/*
 * Prevent overflow in <body />. <Root /> is scrollable.
 */
html,
body,
#__next,
.page,
.root {
    height: 100vh;
    max-height: 100vh;
}

html,
body {
    /* Prevent elastic scrolling on iOS */
    overflow: hidden;

    /* Prevent browser's two finger back/forward behaviour */
    /* <Verse /> tries to set this on the body in JavaScript but it doesn't work. See https://gitlab.com/huddlehq/huddle/-/merge_requests/350#note_456345895 */
    overscroll-behavior-x: none;

    user-select: none;
}

/* Fix dat.gui lineheight */
.dg input {
    line-height: 1.2;
}

.light-purple-selection::selection {
    background-color: #b16eef;
}

.no-outline,
.no-outline:focus {
    outline: none;
}

.emoji-mart * {
    font-family: Inter;
}

.pointer-events-box-none {
    pointer-events: none;
}

.pointer-events-box-none * {
    pointer-events: all;
}

/* pulse */

.pulse {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #b16eef;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
    animation: pulse 2s infinite;
}
.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(177, 110, 239, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(177, 110, 239, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(177, 110, 239, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(177, 110, 239, 0.4);
        box-shadow: 0 0 0 0 rgba(177, 110, 239, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(177, 110, 239, 0);
        box-shadow: 0 0 0 10px rgba(177, 110, 239, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(177, 110, 239, 0);
        box-shadow: 0 0 0 0 rgba(177, 110, 239, 0);
    }
}

.signupCalendarFlex {
    flex-direction: row;
}

.signupCalendarCard {
    width: 272px;
    max-width: 272px;
    margin: 8px;
    margin-bottom: 137px;
}

@media only screen and (max-width: 1168px) {
    .signupCalendarFlex {
        flex-direction: column;
    }

    .signupCalendarCard {
        width: 67vw;
        max-width: 100vw;
        margin-bottom: 16px;
    }

    .signupCalendarCard:last-child {
        margin-bottom: 137px;
    }
}

@media only screen and (max-width: 600px) {
    .signupCalendarCard {
        width: calc(100% - 16px);
    }
}

@media only screen and (min-width: 1168px) {
    .signupCalendarCardTruncate {
        max-width: 184px;
    }
}

.event-description a {
    text-decoration: underline !important;
}

.quickSearchOverlay {
    -webkit-user-select: none;
    -webkit-app-region: drag;
}

.electron-draggable {
    -webkit-app-region: drag;
}

.electron-non-draggable {
    -webkit-app-region: no-drag;
}

*:focus-visible {
    outline: none;
}

@keyframes ephemeral-reaction-keyframes {
    0% {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }

    8% {
        opacity: 1;
        transform: scale3d(1.25, 0.75, 1);
    }

    13% {
        opacity: 1;
        transform: scale3d(0.75, 1.25, 1);
    }

    15% {
        opacity: 1;
        transform: scale3d(1.15, 0.85, 1);
    }

    18% {
        opacity: 1;
        transform: scale3d(0.95, 1.05, 1);
    }

    20%,
    50%,
    75% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(40%) scale(0.5);
        display: none;
    }
}

@keyframes ephemeral-reaction-keyframes-confetti {
    0% {
        opacity: 0;
        transform-origin: bottom left;
        transform: scale3d(1, 1, 1);
    }

    2.25%,
    5% {
        transform-origin: bottom left;
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    7.5%,
    12.5%,
    17.5%,
    22.5% {
        transform-origin: bottom left;
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    10%,
    15%,
    20% {
        transform-origin: bottom left;
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    25%,
    50%,
    75% {
        opacity: 1;
        transform-origin: bottom left;
        transform: scale3d(1, 1, 1);
    }
    100% {
        opacity: 0;
        transform-origin: bottom left;
        transform: translateX(40%) scale(0.5);
    }
}

.ephemeral-reaction-animation {
    opacity: 0;
    animation-name: ephemeral-reaction-keyframes;
}

.ephemeral-reaction-animation-confetti {
    opacity: 0;
    animation-name: ephemeral-reaction-keyframes-confetti;
}

.ephemeral-reaction-flip-horizontally {
    transform: scaleX(-1);
}

@keyframes fullscreen-ephemeral-reaction-keyframes {
    from {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }

    8% {
        opacity: 1;
        transform: scale3d(1.25, 0.75, 1);
    }

    13% {
        opacity: 1;
        transform: scale3d(0.75, 1.25, 1);
    }

    15% {
        opacity: 1;
        transform: scale3d(1.15, 0.85, 1);
    }

    18% {
        opacity: 1;
        transform: scale3d(0.95, 1.05, 1);
    }

    20%,
    50%,
    75% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(40%) scale(0.5);
        display: none;
    }
}

.fullscreen-ephemeral-reaction-animation {
    opacity: 0;
    animation-name: fullscreen-ephemeral-reaction-keyframes;
}

@keyframes sticky-reaction-keyframes {
    0% {
        opacity: 0;
        transform: translateX(-40%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(0%) scale(1);
    }
}

.sticky-reaction-animation {
    opacity: 1;
    animation: sticky-reaction-keyframes 700ms ease-in;
}

@keyframes fullscreen-sticky-reaction-keyframes {
    0% {
        opacity: 0;
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(0deg);
    }
    85% {
        transform: rotate(10deg);
    }
    95% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

.fullscreen-sticky-reaction-animation {
    animation: fullscreen-sticky-reaction-keyframes 700ms ease-in;
}
